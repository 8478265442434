import React from 'react';
import {graphql} from 'gatsby';
import Seo from '../components/Seo';
import Img from "gatsby-image";
import Layout from '../components/Layout';
import {Container, PageTitle} from '../components/visual';
import * as styles from './Projects.module.css';

const Portfolio = ({data}) => {
    const node = data.markdownRemark;
    const imgFluid = node.frontmatter.img && node.frontmatter.img.childImageSharp.fluid;

    return (
        <Layout>
            <Seo title={node.frontmatter.title} description={node.frontmatter.shortContent}/>
            <Container>
                <h1 className={styles.articleDetailTitle}>{node.frontmatter.title}</h1>
                <div className={styles.articleDetail}>
                    {imgFluid && <div className={styles.articleDetailImg}><Img fluid={imgFluid} /></div> }
                    <div  className={styles.articleDetailContent} dangerouslySetInnerHTML={{__html: node.html}} />
                    <div className={styles.articleDetailMeta}>
                        <time className={styles.articleDetailTime}>{data.markdownRemark.frontmatter.date}</time>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

export default Portfolio;

export const query = graphql`
    query($id: String) {
        markdownRemark (id: {eq: $id}) {
            html
            frontmatter {
                title
                slug
                shortContent
                date(formatString: "D. M. YYYY")
                img {
                    childImageSharp {
                        fluid (maxWidth:800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;